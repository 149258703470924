<template>
  <v-dialog
    v-model="show"
    max-width="40vw"
    @click:outside="cancel"
  >
    <v-card
      flat
      class="pa-3 mt-2"
    >
      <v-card-title class="justify-center text-h5">
        {{ label }}
      </v-card-title>
      <v-card-text>
        <v-form
          class="multi-col-validation mt-6" ref="form">
          <v-row>
            <v-col
              cols="12"
              md="6"
            >
              <v-select
                v-model="rk7ClassificatorGroupId"
                :items="classificatorGroups"
                :menu-props="{ maxHeight: '400' }"
                label="Классификаторная группа"
                dense
                :rules="[rules.required]"
                outlined
                persistent-hint
                hide-details
              ></v-select>
            </v-col>
            <v-col
              cols="12"
              md="6"
            >
              <v-textarea
                v-model="logo"
                label="Логотип"
                dense
                :rules="[rules.required]"
                outlined
              ></v-textarea>
            </v-col>
            <v-col
              cols="12"
              md="6"
            >
              <v-text-field
                v-model="urlName"
                label="Наименование в url"
                dense
                outlined
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              md="6"
            >
              <v-checkbox
                v-model="isDefault"
                label="По умолчанию"
                dense
                outlined
              ></v-checkbox>
            </v-col>
            <v-col
              cols="12"
              md="6"
            >
              <v-text-field
                v-model="maxDishAmountInOrder"
                label="Максимальное количество блюд в заказе"
                :rules="[rules.integerValidator]"
                dense
                outlined
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-btn
                @click="saveData"
                color="primary"
                class="me-3 mt-4"
              >
                Сохранить
              </v-btn>
              <v-btn
                color="secondary"
                outlined
                class="mt-4"
                type="reset"
                @click="cancel"
              >
                Отмена
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mdiCheck, mdiClose } from '@mdi/js'
import { mapFormHandlers } from '@/helpers/forms'
import { mapActions, mapGetters } from 'vuex'
import { rules } from '@/helpers/validation'

export default {
  name: 'RestaurantForm',
  props: ['save', 'show', 'cancellation', 'label'],
  data: () => ({
    icons: {
      mdiCheck,
      mdiClose,
    },
    rules,
  }),
  computed: {
    ...mapFormHandlers('restaurant/form', [
      'rk7ClassificatorGroupId',
      'logo',
      'maxDishAmountInOrder',
      'urlName',
      'isDefault',
    ]),
    ...mapGetters('dictionary', { classificatorGroups: 'classificatorGroupDropDown' }),
  },
  async mounted() {
    await this.fetchClassificatorGroups()
  },
  watch: {
    show: function(newValue) {
      if (newValue && this.$refs.form) {
        this.$refs.form.resetValidation()
      }
    },
  },
  methods: {
    cancel() {
      this.resetForm()
      this.cancellation()
    },
    ...mapActions('restaurant/form', ['resetForm']),
    ...mapActions('dictionary', { fetchClassificatorGroups: 'fetchClassificatorGroups' }),
    async saveData() {
      if (this.$refs.form.validate()) {
        await this.save()
        this.resetForm()
      }
    },
  },
}
</script>
